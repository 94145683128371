<template>
  <div class="component-root">
    <div class="message-header">
      <em class="icon-dot"></em>{{ message.b ? related : suggest }}
    </div>
    <ul class="list no-border">
      <li
        v-for="(item, index) in message.content"
        :key="index"
        @click="selectRelItem(item);"
      >{{item.desc}}</li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'RelQuestion',
  props: {
    message: Object
  },
  computed: {
    ...mapState(['related', 'suggest'])
  },
  methods: {
    selectRelItem (selected) {
      // 问题选择后提问问题
      this.$store.dispatch('askQuestion', {
        from: 2,
        question: selected.desc,
        dataType: 'list-selected',
        onlyRobot: true
      })
    }
  }
}
</script>
